import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react'
import { refCartButton } from 'atg/components/legacy-header-r2/cartButton'
import { CurrencyHelpersWithoutBorderConversion } from 'helpers_composable/currencyHelpersWithoutBorderConversion'
import { useFormat } from 'helpers_composable/hooks/useFormat'
import { useAtgLegacyCart, useAtgUser } from 'frontastic/contexts'
import { CartDrawerEmptyState } from './cart-drawer-empty-state'
import { CartDrawerFooter } from './cart-drawer-footer'
import { CartDrawerSummary } from './cart-drawer-summary'
import { useComposable } from '../../composable-provider'
import { HorizontalProductCard } from '../../horizontal-product-card'

export const CartDrawer = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()
  const { cartDrawer } = useComposable()
  const {
    formattedCart: cart,
    removeItem,
    updateItem,
    refreshCart: recalculateCart,
    cartTotalItems,
  } = useAtgLegacyCart()
  const { userSessionData } = useAtgUser()

  const cartIsEmpty = !cartTotalItems || cart?.cartState !== 'Active'
  const [isUpdating, setIsUpdating] = useState(false)
  const locale = cart?.locale?.language

  const handleUpdate = async (updatePromise: Promise<any>) => {
    setIsUpdating(true)
    try {
      await updatePromise
    } catch (err) {
      console.log(err)
    } finally {
      recalculateCart() // Only Recalculate the Cart after the update is done.
    }
    setIsUpdating(false)
  }

  useEffect(() => {
    recalculateCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Drawer
      finalFocusRef={refCartButton}
      isOpen={cartDrawer.isOpen}
      placement="right"
      onClose={cartDrawer.onClose}
      size={'md'}
    >
      <DrawerOverlay />
      <DrawerContent maxW={{ base: 375, md: 550 }}>
        <DrawerHeader p={'12px 36px'}>
          <DrawerCloseButton size={'sm'} fontSize={'sm'} left={'xs'} mt={'2px'} />
          <Center h={'24px'} fontSize={{ base: '1rem', md: '1.25rem' }} lineHeight={'1.5rem'}>
            <Text tabIndex={0} textStyle={'heading-desktop-100'}>
              {formatMessage({ id: 'cart.drawer.titleCount', values: { count: cartTotalItems } })}
            </Text>
          </Center>
        </DrawerHeader>
        <Divider mb={'1em'} />
        <DrawerBody>
          {!locale || !cart || cartIsEmpty ? (
            <CartDrawerEmptyState onClose={cartDrawer.onClose} />
          ) : (
            <Stack spacing={2}>
              <List>
                <Stack spacing={2} divider={<Divider />}>
                  {cart?.lineItems?.map((item, i) => {
                    const {
                      _url,
                      availableDiscount,
                      count,
                      discountedPrice,
                      isDiscounted,
                      lineItemId,
                      name,
                      price,
                      totalDiscountedPrice,
                      totalPrice,
                      variant,
                      masterData,
                      type: commerceItemClassType,
                      hemmingPriceInfo = '',
                      hemmingPrice = 0,
                      availabilityStatus,
                      itemFinalDisplayPrice,
                      promoDiscounts,
                    } = item

                    const masterProductData = masterData?.current?.masterVariant?.attributes
                    const isDonationOrGiftCard = commerceItemClassType === 'giftCardCommerceItem'
                    return (
                      <ListItem key={lineItemId}>
                        <HorizontalProductCard
                          itemFinalDisplayPrice={itemFinalDisplayPrice}
                          promoDiscounts={promoDiscounts}
                          availableDiscount={availableDiscount}
                          url={_url}
                          editable
                          columns={3}
                          size="sm"
                          image={{
                            src: variant?.images?.[0],
                            alt: name ?? '',
                            onClickImage: async () => {
                              await router.push(_url)
                              cartDrawer.onClose()
                            },
                          }}
                          name={name || ''}
                          quantity={count}
                          isOnStock={variant.isOnStock}
                          quantityPickerProps={{
                            max: variant?.availableQuantity ?? 99,
                          }}
                          priceCentAmount={price?.centAmount}
                          price={CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                            price?.centAmount,
                            userSessionData?.dxlCurrency,
                          )}
                          isDiscounted={isDiscounted}
                          discountedPrice={CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                            discountedPrice?.centAmount,
                            userSessionData?.dxlCurrency,
                          )}
                          // used in loyalty points
                          totalPriceCentAmount={isDiscounted ? totalDiscountedPrice?.centAmount : totalPrice.centAmount}
                          totalPrice={CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                            totalPrice.centAmount,
                            userSessionData?.dxlCurrency,
                          )}
                          totalCentPrice={totalPrice.centAmount}
                          totalDiscountedPrice={CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                            totalDiscountedPrice.centAmount,
                            userSessionData?.dxlCurrency,
                          )}
                          totalDiffBtwPriceAndDiscountedPrice={
                            totalPrice?.centAmount - totalDiscountedPrice?.centAmount
                          }
                          variant={variant}
                          onRemove={() => handleUpdate(removeItem(variant?.attributes?.removalId))} // this is for ATGCart context - for commercetools, jut use the productId
                          onChangeQuantity={async (val) => {
                            val === 0
                              ? // ? await handleUpdate(removeItem(lineItemId)) this is when we use CT cart
                                handleUpdate(removeItem(variant?.attributes?.removalId))
                              : await handleUpdate(updateItem(lineItemId, val, name))
                          }}
                          isLoading={isUpdating}
                          displayOutOfStock={variant.isOnStock}
                          masterProductData={masterProductData}
                          isDonationOrGiftCard={isDonationOrGiftCard}
                          hemmingPriceInfo={hemmingPriceInfo}
                          hemmingPrice={hemmingPrice}
                          availabilityStatus={availabilityStatus}
                          removedSegment3LabelWording={true}
                        />
                      </ListItem>
                    )
                  })}
                </Stack>
                <Divider mt={4} />
              </List>
              {/* removing this for R2 launch */}
              {process.env.NEXT_PUBLIC_APP_VERSION !== 'R2' && <CartDrawerSummary />}
            </Stack>
          )}
        </DrawerBody>
        {cart && !cartIsEmpty && (
          <DrawerFooter p={'0px 20px 16px'} boxShadow={'0px -2px 4px rgba(0, 0, 0, 0.1)'}>
            <CartDrawerFooter />
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}
